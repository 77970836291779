<template>
  <div class="">
    <v-list-item class="pl-3 pa-0">
      <v-list-item-avatar :size="deger">
        <v-img :src="require('@/assets/app.svg')" />
      </v-list-item-avatar>

      <v-list-item-content class="">
        <v-list-item-title class="text-h4">
          <strong class="mr-1 font-weight-black">beefreund </strong>
        </v-list-item-title>
        <v-list-item-subtitle>
          <small>{{ version }}</small>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon>
        <v-btn icon plain @click="cikis">
          <v-icon dark>
            mdil-logout
          </v-icon>
        </v-btn>
      </v-list-item-icon>
    </v-list-item>

    <v-list-item v-if="!mini" class="mt-6">
      <v-list-item-content>
        <div class="d-flex align-center">
          <v-btn
            color="primary"
            depressed=""
            to="/profil"
            small
            class="text-capitalize"
          >
            {{ $t("profil") }}
            <v-icon right dark>
              mdi-account
            </v-icon>
          </v-btn>
          <v-btn fab small class="ml-3" @click="dialog = true">
            <v-icon :color="ayarlar.alisverisDurum ? 'success' : 'error'">
              mdi-circle-slice-8
            </v-icon>
          </v-btn>
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-dialog v-model="dialog" persistent="" width="350">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("alisverisDurum") }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div class="pt-3">
            {{ $t("alisverisDurumYazi") }}
          </div>

          <v-radio-group v-model="alisveriDurumOnay" mandatory="">
            <v-radio :label="$t('acikD')" :value="true" />
            <v-radio :label="$t('kapaliD')" :value="false" />
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false">
            {{ $t("vazgec") }}
          </v-btn>
          <v-btn color="primary" depressed="" @click="alisveriDurumKaydet()">
            {{ $t("kaydet") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";
import { veri, giris } from "./../../../db";
export default {
  name: "DefaultDrawerHeader",
  data: () => ({
    dialog: false,
    alisveriDurumOnay: true,
    ayarlar: {
      alisverisDurum: ""
    }
  }),
  computed: {
    version: get("app/version"),
    mini: get("app/mini"),
    deger() {
      let deger = 40;
      if (!this.mini) {
        deger = 60;
      }
      return deger;
    }
  },
  firebase: {
    ayarlar: veri.ref("ayarlar")
  },

  methods: {
    alisveriDurumKaydet() {
      veri
        .ref("ayarlar/alisverisDurum")
        .set(this.alisveriDurumOnay)
        .then(() => {
          this.dialog = false;
        });
    },
    cikis() {
      giris.signOut().then(() => {
        this.$router.replace("/login");
      });
    }
  }
};
</script>
